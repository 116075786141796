
<template>
  <div class="footer minwidth">
    <div class="footer-box">
      <div class="footer-box-company">
        <div class="footer-box-company-logo">
          <img alt="">
        </div>
        <div class="footer-box-company-txt">
          <p class="fs14">全渠道智能云客服平台</p>
        </div>
      </div>
      <div class="footer-box-main">
        <div>
          <p class="fs18">功能</p>
          <p class="fs14">在线客服</p>
          <p class="fs14">智能机器人</p>
          <p class="fs14">移动客服APP</p>
          <p class="fs14">功能定制</p>
        </div>

      </div>
      <div class="footer-box-code">
        <!--
        <img  alt="" >
        -->
      </div>
    </div>
    <div class="footer-tip">
      <div>Copyright © 宜信云<br><a href="http://beian.miit.gov.cn" target="_blank">粤ICP备2021007129号</a></div>
    </div>
  </div>
</template>



<script>
    export default {
        data() {
            return {};
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
.footer{
    width: 100%;
    height: 355px;
    background-size: 100% 100%;
    padding: 40px 0;
    background: $theme-color;
    box-sizing: border-box;
    &-box{
        width: 1200px;
        margin: 0px auto 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-company{
          display: flex;
          align-items: center;
          flex-direction: column;
          &-logo{
            margin-bottom: 20px;
          }
          &-txt{
            color: #999999;
            p{
              margin-bottom: 30px;
            }
          }
        }
        &-main{
          color: #999999;
          line-height: 30px;
          font-size: 14px;
          padding: 0 95px 0 112px;
          display: flex;
          justify-content: space-between;
          width: 600px;
        }
        &-code{
          img{
            border:1px solid #ccc;
            height: 200px;
            width: 200px;
          }
        }
    }
    &-tip{
        height: 55px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          color: white;
          text-decoration: none;
        }
        div{
            width: 300px;
            text-align: center;
            color:white;
            font-size: 13px;
            &:nth-last-of-type(1){
                border: none;
            }
        }
    }
}
</style>